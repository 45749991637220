<template>
  <div class="comparison-wrapper">
    <Loader :loading="isLoader" v-if="isLoader" />
    <div class="container" v-else>
      <div>
        <div class="flex flex-row mb-4">
          <div class="flex flex-row pr-12 w-64">
            <label class="label mr-3">CUC Code1:</label>
            <label class="label font-bold">{{ cucCode1 }}</label>
          </div>
          <div class="flex flex-row pr-12 w-64">
            <label class="label mr-3">Revision:</label>
            <label class="label font-bold">{{ revisionPrimary.Value }}</label>
          </div>
          <div class="flex flex-row">
            <label class="label mr-3">Description:</label>
            <label class="label font-bold">{{ revisionPrimary.Info }}</label>
          </div>
        </div>

        <div class="flex flex-row pb-6">
          <div class="flex flex-row pr-12 w-64">
            <label class="label mr-3">CUC Code2:</label>
            <label class="label font-bold">{{ cucCode2 }}</label>
          </div>
          <div class="flex flex-row pr-12 w-64">
            <label class="label mr-3">Revision:</label>
            <label class="label font-bold">{{ revisionSecondary.Value }}</label>
          </div>
          <div class="flex flex-row">
            <label class="label mr-3">Description:</label>
            <label class="label font-bold">{{ revisionSecondary.Info }}</label>
          </div>
        </div>

        <div class="overflow-y-scroll table-wrapper">
          <Table
            :table-headers="tableHeaders"
            :table-data-rows="tableDataRows"
            v-if="tableDataRows.length > 0"
          />
          <!--NO DATA-->
          <NoDetailsBox v-else />
        </div>
        <div class="float-right table-footer" v-if="tableDataRows.length > 0">
          <div>Total Weight</div>
          <div>{{ cuc1PercentageTotal }}</div>
          <div>{{ cuc2PercentageTotal }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import loggerService from "../../services/logger";

export default {
  components: {
    Loader: () => import(/* webpackPrefetch: true */ "../common/Loader"),
    Table: () => import(/* webpackPrefetch: true */ "../common/Table"),
    NoDetailsBox: () =>
      import(/* webpackPrefetch: true */ "../common/NoDetailsBox"),
  },
  props: {},
  data: () => ({
    isLoader: true,
    cuc1PercentageTotal: null,
    cuc2PercentageTotal: null,
    cucCode1: null,
    cucCode2: null,
    revision1: null,
    revision2: null,
    tableHeaders: [
      {
        ING: "ING",
        Empty: "",
        Cuc1: "Aqua",
        Cuc2: "",
      },
    ],
    tableDataRows: [],
  }),
  computed: {
    ...mapGetters({
      cucCodePrimary: "GET_CUC_CODE_PRIMARY",
      cucCodeSecondary: "GET_CUC_CODE_SECONDARY",
      revisionPrimary: "GET_REVISION_PRIMARY",
      revisionSecondary: "GET_REVISION_SECONDARY",
    }),
  },
  created() {
    this.getComparisonData();
  },
  methods: {
    async getComparisonData() {
      const self = this;
      if (self.cucCodeSecondary === null) {
        self.isLoader = false;
      } else {
        try {
          const { data } = await self.$http
            .get(
              `${process.env.VUE_APP_API_URL}/FormulationMapping/CompareCucCodes/${self.cucCodePrimary}/${self.cucCodeSecondary}/${self.revisionPrimary.Value}/${self.revisionSecondary.Value}`
            )
            .catch((error) => {
              self.isLoader = false;
              loggerService.logError(error);
            });
          self.isLoader = false;
          self.tableDataRows =
            data.IngCucPercentages === null ? [] : data.IngCucPercentages;
          self.cuc1PercentageTotal = data.Cuc1PercentageTotal;
          self.cuc2PercentageTotal = data.Cuc2PercentageTotal;
          self.cucCode1 = self.cucCodePrimary;
          self.cucCode2 = self.cucCodeSecondary;
          self.revision1 == self.revisionPrimary;
          self.revision2 = self.revisionSecondary;
        } catch (error) {
          self.isLoader = false;
          self.tableDataRows = [];
         loggerService.logError(error);
        }
      }
    },
  },
};
</script>

<style lang="scss">
.comparison-wrapper {
  background: #fff;
  margin: 5px 8px;
  padding: 10px 10px 38px 10px;
  border-radius: 3px;
}
.table-footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
  div {
    width: 90px;
    font-weight: bold;
  }
  div:nth-child(2) {
    width: 210px;
  }
  div:nth-child(3) {
    width: 220px;
  }
  *::-ms-backdrop,
  div:nth-child(1) {
    width: 156px;
  }
  *::-ms-backdrop,
  div:nth-child(2) {
    width: 154px;
  }
  *::-ms-backdrop,
  div:nth-child(3) {
    width: 167px;
  }
}
.table-container {
  width: 100%;
}
.table-wrapper {
  max-height: 265px;
  min-height: 100px;
}
.overflow-y-scroll {
  overflow-y: auto;
}
.ingredient {
  width: 170px !important;
  max-width: 170px !important;
}
</style>
