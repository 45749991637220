var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "comparison-wrapper" },
    [
      _vm.isLoader
        ? _c("Loader", { attrs: { loading: _vm.isLoader } })
        : _c("div", { staticClass: "container" }, [
            _c("div", [
              _c("div", { staticClass: "flex flex-row mb-4" }, [
                _c("div", { staticClass: "flex flex-row pr-12 w-64" }, [
                  _c("label", { staticClass: "label mr-3" }, [
                    _vm._v("CUC Code1:")
                  ]),
                  _c("label", { staticClass: "label font-bold" }, [
                    _vm._v(_vm._s(_vm.cucCode1))
                  ])
                ]),
                _c("div", { staticClass: "flex flex-row pr-12 w-64" }, [
                  _c("label", { staticClass: "label mr-3" }, [
                    _vm._v("Revision:")
                  ]),
                  _c("label", { staticClass: "label font-bold" }, [
                    _vm._v(_vm._s(_vm.revisionPrimary.Value))
                  ])
                ]),
                _c("div", { staticClass: "flex flex-row" }, [
                  _c("label", { staticClass: "label mr-3" }, [
                    _vm._v("Description:")
                  ]),
                  _c("label", { staticClass: "label font-bold" }, [
                    _vm._v(_vm._s(_vm.revisionPrimary.Info))
                  ])
                ])
              ]),
              _c("div", { staticClass: "flex flex-row pb-6" }, [
                _c("div", { staticClass: "flex flex-row pr-12 w-64" }, [
                  _c("label", { staticClass: "label mr-3" }, [
                    _vm._v("CUC Code2:")
                  ]),
                  _c("label", { staticClass: "label font-bold" }, [
                    _vm._v(_vm._s(_vm.cucCode2))
                  ])
                ]),
                _c("div", { staticClass: "flex flex-row pr-12 w-64" }, [
                  _c("label", { staticClass: "label mr-3" }, [
                    _vm._v("Revision:")
                  ]),
                  _c("label", { staticClass: "label font-bold" }, [
                    _vm._v(_vm._s(_vm.revisionSecondary.Value))
                  ])
                ]),
                _c("div", { staticClass: "flex flex-row" }, [
                  _c("label", { staticClass: "label mr-3" }, [
                    _vm._v("Description:")
                  ]),
                  _c("label", { staticClass: "label font-bold" }, [
                    _vm._v(_vm._s(_vm.revisionSecondary.Info))
                  ])
                ])
              ]),
              _c(
                "div",
                { staticClass: "overflow-y-scroll table-wrapper" },
                [
                  _vm.tableDataRows.length > 0
                    ? _c("Table", {
                        attrs: {
                          "table-headers": _vm.tableHeaders,
                          "table-data-rows": _vm.tableDataRows
                        }
                      })
                    : _c("NoDetailsBox")
                ],
                1
              ),
              _vm.tableDataRows.length > 0
                ? _c("div", { staticClass: "float-right table-footer" }, [
                    _c("div", [_vm._v("Total Weight")]),
                    _c("div", [_vm._v(_vm._s(_vm.cuc1PercentageTotal))]),
                    _c("div", [_vm._v(_vm._s(_vm.cuc2PercentageTotal))])
                  ])
                : _vm._e()
            ])
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }